@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.auth {
  display: flex;
  justify-content: center;
  align-items: center;
  .form {
    .image-container {
      img {
        width: 100px;
      }
    }
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 95vw;
    max-width: 300px;
    .buttons-container {
      min-width: 230px;
      max-width: 230px;
    }
    h4 {
      text-align: center;
      margin-top: 0;
    }
    .disclaimer {
      margin-top: 15px;
      font-size: 9px;
      text-align: center;
    }
  }
}
