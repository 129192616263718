@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.takein {
  max-width: 320px;
  margin: 0 auto;

  .icons-container {
    margin-left: 20px;
    color: gray;

    .selected {
      color: white;
    }
    svg {
      width: 36px;
      height: 36px;
    }
  }
}
