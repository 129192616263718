@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.assign-roles {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  > div {
    width: 320px;
  }

  .user-types {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    max-height: 40%;

    .type {
      margin: auto;
      min-width: 200px;
      padding: 10px;
      background-color: gray;
      text-align: center;
      border-radius: 10px;
      .MuiButton-label {
        pointer-events: none;
      }
      &.current {
        .MuiButton-label {
          color: black;
        }
        background-color: white;
      }
    }
  }
}
