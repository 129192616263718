@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.user-list {
  .MuiInputLabel-animated {
    color:white;
  }
  .search-input{
    margin: 10px 0;

  }
  .user {
    & > * {
      pointer-events: none;
    }
    &.selected {
      background-color: black;
    }
    padding: 5px 10px;
    background-color: gray;
    color: white;
    display: flex;
    border-bottom: solid 1px lightgray;
    align-items: center;
    img {
      width: 20px;
      margin: 5px;
      box-sizing: border-box;
    }
    .MuiSvgIcon-root {
      margin-left: auto;
    }
    div
    {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}