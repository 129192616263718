// Breakpoints
$tabletBreakpoint: 600px;
$desktopBreakpoint: 960px;

// Media Queries
$tablet: "only screen and (min-width : #{$tabletBreakpoint})";
$desktop: "only screen and (min-width : #{$desktopBreakpoint})";

// Colors
$gray: #d2d2d2;
$dark: #2b2b2b;
