@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.unit-selector {
  margin: 50px 0;

  .buttons-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .MuiButton-contained {
      margin-left: auto;
      &.Mui-disabled {
        color: gray;
        &.debtor {
          background-color: pink;
          color: red;
        }
      }
    }

    .unit {
      color: red;
      text-align: center;
      font-size: 30px;
      min-width: 30%;
      &.valid {
        color: green;
      }
    }
  }
  .alert {
    color: red;
    text-align: center;
    background-color: pink;
    padding: 20px 10px;
    border-radius: 5px;
    margin-top: 10px;
    word-wrap: break-word;
  }
}
