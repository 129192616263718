@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.couriers-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 10px 0;
  div {
    background-color: lightgray;
    width: 48px;
    height: 48px;
    margin: 2px;
    border-radius: 50%;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    img {
      filter: grayscale(100%);
      width: 100%;
    }
    label{
      font-size: 12px;
    }
    &.selected {
      border: solid 5px orange;
      background-color: white;
      img {
        filter: none;
      }
    }
  }
}
