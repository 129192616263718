@import "../../styles/variables.scss";

tr {
  cursor: pointer;
  .vendor{
    width: 45px;
  }
  &.active {
    background-color: $gray;
  }
}
