@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  color: white;
  & > * {
    flex: 1 0 100%;
    text-align: center;
  }
}
