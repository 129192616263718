.content {
  display: flex;
  align-items: stretch; 
  min-height: calc(100vh - 70px);
  background-color: #2b2b2b;
  @media only screen and (max-width: 574px) {
    min-height: calc(100vh - 59px);
    position: absolute;
    width: 100%;
    top: 59px;
  }
  >*{
    flex-basis: 100%;
  }
}
