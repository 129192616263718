@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.debtors {
  .list{
    margin: 20px;
    .MuiChip-root{
      margin: 5px;
    }
  }
  .unit-selector {
    max-width: 320px;
    margin: 0 auto;
  }
}
