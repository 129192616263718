@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.notifications {
   display: flex;
   justify-content: center;
   align-items: center;
   flex-wrap: wrap;
   .interactive{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      .MuiTypography-root{
         width: 80%;
         margin-bottom: 50px;
   }
}
}
