@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.link-users {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  > div {
    width: 320px;
  }

}
