.contact-us {
  padding-top: 100px;
  background-color: #f4f4f4;
  min-height: 100vh;

  &__container {
    background-color: white;
    padding: 20px;
    max-width: 1140px;
    margin: auto;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
  }

  &__form {
    display: flex;
    flex-direction: column;

    div:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}
