@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.items-display {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 10px 0px;
  &__item {
    box-sizing: border-box;
    padding: 10px;
    background-color: gray;
    margin: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    vertical-align: middle;
    text-align: center;
    &.selected{
      background-color: white;
    }
  }
  &__title {
    margin-top: 10px;
    text-align: center;
    color: white;
  }
}
