@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.takeout {
  max-width: 320px;
  margin: 0 auto;
  .table-container {
    margin: 20px 0;
  }
}
