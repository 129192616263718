@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';


.ButtonProvider{
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width:100%;
    padding: 2%;
    background-color: lightgray;
    margin: 5px 0px;
    color: black;
    font-size: 0.8em;
    border-radius: 3px;
    filter: grayscale(100%);
    img{
      width: 12%;
      margin: 0 7%;
      box-sizing: border-box;
    }
    &:link,&:visited {
      text-decoration: none;
  
    }
}


